<template>
  <div class="card">
    <div>
      <validation-observer ref="addRisk">
        <b-form>
          <b-row>
            <b-col class="marin-20">
              <b-form-group>
                <label>Name</label>
                <br>
                <validation-provider
                  #default="{ errors }"
                  name="Risk Name"
                  rules="required"
                >
                  <b-form-input
                    v-model="RiskName"
                    id="v-first-name"
                    class="mr-bottom"
                    placeholder="Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <br>
                <label>Type</label>
                <v-select
                  v-model="selectedData"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="option"
                  multiple
                />
                
                <label>Risk treatment plan</label>
                
                <validation-provider
                  #default="{ errors }"
                  name="treatment plan"
                  rules="required"
                >
                <b-form-textarea
                  v-model="RiskTreatmentPlan"
                  id="v-first-name"
                  class="mr-bottom"
                  placeholder="Name"
                />
                 <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                  <br>
                <label>Vulnerabilities</label>
              
                 <!-- <validation-provider
                  #default="{ errors }"
                  name="Vulnerability"
                  rules="required"
                > -->
                <vue-autosuggest
                  :suggestions="vulnerabilitiesOptions"
                  :limit="10"
                  v-model="selectedVulnerbilityName"
                  id="autosuggest__input"
                  :input-props="tagInputProps"
                  @input="getSearchVulnerabilities"
                  @selected="onTagSelected"
                  :get-suggestion-value="getTagSuggestionValue"
                >
                  <template
                    slot-scope="{ suggestion }"
                    style="display: flex; align-items: center"
                  >
                    <span style="{ display: 'flex', color: 'navyblue'}">{{
                      suggestion.item
                    }}</span>
                  </template>
                </vue-autosuggest>
                <!-- <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider> -->
                <div>
                  <ul class="ul-auto-suggest">
                    <li v-for="data in selectedEditData">
                      <span>
                        {{ data.tag_title }}
                      </span>
                      <feather-icon
                        @click="closeData(data.tag_id)"
                        class="text-primary pointer rotate"
                        icon="XIcon"
                        size="15"
                      />
                    </li>
                  </ul>
                </div>
                
                <label>Threats</label>
               
                <validation-provider
                  #default="{ errors }"
                  name="Threat"
                  rules="required"
                >
                <v-select
                  class="mr-bottom"
                  v-model="selectedThreats"
                  placeholder="Search"
                  label="text"
                  :options="ThreatsOptions"
                  @search="getSearchThreats"
                />
                 <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <br>
                <label>Onecsf control</label>
                <v-select
                  class="mr-bottom"
                  v-model="selectedControl"
                  placeholder="Search"
                  label="text"
                  multiple
                  :options="seletedControlOptions"
                  @search="getSearchControls"
                />

                <label>Description</label>
                <b-form-textarea
                  class="mr-bottom"
                  rows="1"
                  v-model="riskDescription"
                  placeholder="description"
                />
              </b-form-group>
            </b-col>
            <!-- submit and reset -->
            <b-col class="marin-20" cols="12">
              <b-button
                @click="addRiskData()"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
              >
                Add
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                @click="cancelAddRisk()"
              >
                Cancel
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { VueAutosuggest } from "vue-autosuggest";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BFormTextarea,
  BPagination,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
} from "bootstrap-vue";
export default {
  data: () => ({
    RiskName: "",
    searchResultRiskName: "",
    riskDescription: "",
    autoData: [],
    vulnerabilitiesOptions: [],
    selectedVulnerabilities: [],
    ThreatsOptions: [],
    selectedThreats: [],
    allThreats: [],
    seletedControlOptions: [],
    selectedControl: [],
    controlData: [],
    RiskTreatmentPlan: "",
    dir: "ltr",
    selectedData: [],
    option: [{ title: "Security" }, { title: "Privacy" }],
    selectedVulnerbilityName: "",
    tagInputProps: {
      class: "form-control",
      placeholder: "Add new",
    },
    selectedEditData: [],
    allVulnerabilities: [],
    selectedThreatName: "",
    selectedEditThreatData: [],
    allThreat: [],
    standardType: "",
    required,
  }),
  components: {
    BFormTextarea,
    BPagination,
    vSelect,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    VueAutosuggest,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {},
  mounted() {
    this.getVulnerabilities();
    this.getThreat();
  },
  computed: {},
  methods: {
    closeData(data) {
      this.selectedEditData = this.selectedEditData.filter(
        (ele) => ele.tag_id != data
      );
    },
    getSearchControls(name) {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL +
          `/control/risk_primary?type[1]=${
            this.selectedData[1] && this.selectedData[1].title
              ? this.selectedData[1].title
              : null
          }&type[0]=${
            this.selectedData[0] && this.selectedData[0].title
              ? this.selectedData[0].title
              : null
          }&name=${name}`,
      };
      this.$http(options)
        .then((res) => {
          this.controlData = res.data.data;
          this.seletedControlOptions = [];
          // this.selectedControl = []

          for (let [key, value] of Object.entries(this.controlData)) {
            let a = {
              value: value._id,
              text: value.name,
            };
            this.seletedControlOptions.push(a);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onTagSelected(val) {
      if (val) {
        const selectedVal = val.item;
        this.allVulnerabilities.filter((ele) => {
          if (ele.name == selectedVal) {
            if (!this.selectedEditData.find((o) => o.tag_id === ele._id)) {
              this.selectedEditData.push({
                tag_id: ele._id,
                tag_title: ele.name,
              });
            }
            this.selectedVulnerbilityName = "";
          }
        });
      }
    },

    getTagSuggestionValue(suggestion) {
      return suggestion.item;
    },
    onTagSelectedThreats(val) {
      console.log("val", val);

      if (val) {
        const selectedVal = val.item;
        this.allThreat.filter((ele) => {
          if (ele.name == selectedVal) {
            this.selectedEditThreatData.push({
              tag_id: ele._id,
              tag_title: ele.name,
            });
            this.selectedThreatName = "";
          }
        });
      }
    },
    getTagSuggestionValueThreats(suggestion) {
      return suggestion.item;
    },
    getThreat() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/threats`,
      };
      this.$http(options)
        .then((res) => {
          this.allThreat = res.data.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addRiskData() {
         this.$refs.addRisk.validate().then(valid => {
        if (!valid) {
          // do stuff if not valid.
        }
        else{

      const vulnerabilityID = [];
      const threatsID = [];
      const control = [];
      const selectedDataType = [];
      this.selectedData.forEach((ele) => {
        selectedDataType.push(ele.title);
      });
      this.selectedEditData.forEach((ele) => {
        vulnerabilityID.push(ele.tag_id);
      });
      this.selectedControl.forEach((ele) => {
        control.push(ele.value);
      });
      const body = {
        name: this.RiskName,
        description: this.riskDescription,
        vulnerability_id: vulnerabilityID,
        threat_id: this.selectedThreats.value,
        onecsf_control_id: control,
        treatment_plan: this.RiskTreatmentPlan,
        type: selectedDataType,
      };

      const options = {
        method: "POST",
        data: body,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/risks`,
      };
      this.$http(options)
        .then((res) => {
        
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
            this.$router.push({ name: "risk" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
        }
        });
    },
    cancelAddRisk(){
      this.$router.push({ name: "risk" });
    },
    getSearchVulnerabilities(name) {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL +
          `/vulnerability/search?name=${name}`,
      };
      this.$http(options)
        .then((res) => {
          this.autoData = res.data.data;
          for (let [key, value] of Object.entries(this.autoData)) {
            let a = { data: [value.name] };
            this.vulnerabilitiesOptions.push(a);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getVulnerabilities() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/vulnerabilities`,
      };
      this.$http(options)
        .then((res) => {
          this.allVulnerabilities = res.data.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getSearchThreats(name) {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/threat/search?name=${name}`,
      };
      this.$http(options)
        .then((res) => {
          this.allThreats = res.data.data;
          this.ThreatsOptions = [];
          // this.selectedThreats = []

          for (let [key, value] of Object.entries(this.allThreats)) {
            let a = {
              value: value._id,
              text: value.name,
            };
            this.ThreatsOptions.push(a);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.width-20 {
  width: 20%;
}
.full-width {
  width: 100%;
}
.flex {
  display: flex;
}
.mr-right {
  margin-right: 10px;
}
.width-40 {
  width: 40%;
}
.mr-bottom {
  margin-bottom: 10px;
}
.marin-20 {
  margin: 20px;
}
.ul-auto-suggest {
  list-style: none;
  padding: 0;
  display: inline-flex;
}
.ul-auto-suggest li {
  padding: 5px;
  border: 1px solid #6610f2;
  border-radius: 5px;
  margin-top: 8px;
  margin-right: 8px;
}
</style>
