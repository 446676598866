var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_c('div',[_c('validation-observer',{ref:"addRisk"},[_c('b-form',[_c('b-row',[_c('b-col',{staticClass:"marin-20"},[_c('b-form-group',[_c('label',[_vm._v("Name")]),_c('br'),_c('validation-provider',{attrs:{"name":"Risk Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mr-bottom",attrs:{"id":"v-first-name","placeholder":"Name"},model:{value:(_vm.RiskName),callback:function ($$v) {_vm.RiskName=$$v},expression:"RiskName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('br'),_c('label',[_vm._v("Type")]),_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.option,"multiple":""},model:{value:(_vm.selectedData),callback:function ($$v) {_vm.selectedData=$$v},expression:"selectedData"}}),_c('label',[_vm._v("Risk treatment plan")]),_c('validation-provider',{attrs:{"name":"treatment plan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{staticClass:"mr-bottom",attrs:{"id":"v-first-name","placeholder":"Name"},model:{value:(_vm.RiskTreatmentPlan),callback:function ($$v) {_vm.RiskTreatmentPlan=$$v},expression:"RiskTreatmentPlan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('br'),_c('label',[_vm._v("Vulnerabilities")]),_c('vue-autosuggest',{attrs:{"suggestions":_vm.vulnerabilitiesOptions,"limit":10,"id":"autosuggest__input","input-props":_vm.tagInputProps,"get-suggestion-value":_vm.getTagSuggestionValue},on:{"input":_vm.getSearchVulnerabilities,"selected":_vm.onTagSelected},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var suggestion = ref.suggestion;
return [_c('span',{staticStyle:{"{ display":"'flex', color: 'navyblue'}"}},[_vm._v(_vm._s(suggestion.item))])]}}]),model:{value:(_vm.selectedVulnerbilityName),callback:function ($$v) {_vm.selectedVulnerbilityName=$$v},expression:"selectedVulnerbilityName"}}),_c('div',[_c('ul',{staticClass:"ul-auto-suggest"},_vm._l((_vm.selectedEditData),function(data){return _c('li',[_c('span',[_vm._v(" "+_vm._s(data.tag_title)+" ")]),_c('feather-icon',{staticClass:"text-primary pointer rotate",attrs:{"icon":"XIcon","size":"15"},on:{"click":function($event){return _vm.closeData(data.tag_id)}}})],1)}),0)]),_c('label',[_vm._v("Threats")]),_c('validation-provider',{attrs:{"name":"Threat","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"mr-bottom",attrs:{"placeholder":"Search","label":"text","options":_vm.ThreatsOptions},on:{"search":_vm.getSearchThreats},model:{value:(_vm.selectedThreats),callback:function ($$v) {_vm.selectedThreats=$$v},expression:"selectedThreats"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('br'),_c('label',[_vm._v("Onecsf control")]),_c('v-select',{staticClass:"mr-bottom",attrs:{"placeholder":"Search","label":"text","multiple":"","options":_vm.seletedControlOptions},on:{"search":_vm.getSearchControls},model:{value:(_vm.selectedControl),callback:function ($$v) {_vm.selectedControl=$$v},expression:"selectedControl"}}),_c('label',[_vm._v("Description")]),_c('b-form-textarea',{staticClass:"mr-bottom",attrs:{"rows":"1","placeholder":"description"},model:{value:(_vm.riskDescription),callback:function ($$v) {_vm.riskDescription=$$v},expression:"riskDescription"}})],1)],1),_c('b-col',{staticClass:"marin-20",attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.addRiskData()}}},[_vm._v(" Add ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.cancelAddRisk()}}},[_vm._v(" Cancel ")])],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }